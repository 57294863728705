<template>
  <v-card class="pa-2" style="min-height: 90vh; max-height: 90vh; overflow-y: hidden">
    <v-card-title>
      <span>{{ $lang.header.VALIDATE }}</span>
      <v-spacer></v-spacer>
      <v-btn
        icon
        color="primary"
        text
        large
        @click="$emit('closeDialog', true)"
      >
        X
      </v-btn>
    </v-card-title>
    <v-row no-gutters>
      <v-col cols="12">
        <div v-if="data" style="width: 100%; overflow-y: auto" class="py-2 inner-div">
          <template>
            <p class="color-secondary"><span class="color-primary">{{ $lang.labels.name }}</span>: {{ data.name }}</p>
            <p class="color-secondary">
              <span class="color-primary">{{ $lang.labels.versionPersistence }}</span>: {{ data.version.persistence ? data.version.persistence : $lang.labels.nonExistent }}
            </p>

            <h5 class="color-primary mt-4">{{ data.fields.persistence.length ? '' : $lang.labels.no }} {{ $lang.header.persistence }} {{ data.fields.persistence.length ? '' : $lang.labels.fields }}</h5>
            <v-data-table
              v-if="data.fields.persistence.length"
              
              :headers="headers"
              :items="data.fields.persistence"
              item-key="id"
              class="elevation-0 background-transparent"
              hide-default-footer
              :items-per-page="-1"
            >
              <template v-slot:item.dataType="{ item }">
                <div>{{ $lang.status[item.dataType] }}</div>
              </template>
              <template v-slot:item.unique="{ item }">
                <div>{{ item.unique ? $lang.labels.yes : $lang.labels.no }}</div>
              </template>
              <template v-slot:item.primaryKey="{ item }">
                <div>{{ item.primaryKey ? $lang.labels.yes : $lang.labels.no }}</div>
              </template>
              <template v-slot:item.searchType="{ item }">
                <div>{{ $lang.status[item.searchType] }}</div>
              </template>
              <template v-slot:item.required="{ item }">
                <div>{{ item.required ? $lang.labels.yes : $lang.labels.no }}</div>
              </template>
              <template v-slot:item.relation="{ item }">
                <div>{{ item.relation ? item.relation.entityName : $lang.labels.no }}</div>
              </template>
            </v-data-table>
          </template>
        </div>
        <div v-else class="fill-height d-flex justify-center align-center">
          <p>No data to show</p>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'

//TODO DRY ValidateGenerateResultModal
export default {
  name: 'ValidateGenerateResultModalFieldsOnly',
  props: {
    data: {
      type: Object,
      default: () => null
    }
  },
  data () {
    return {
      tab: 0,
      headers: [
        { text: this.$lang.labels.name, value: 'name', sortable: false },
        { text: this.$lang.labels.dataType, value: 'dataType', sortable: false },
        { text: this.$lang.labels.primaryKey, value: 'primaryKey', sortable: false },
        { text: this.$lang.labels.unique, value: 'unique', sortable: false },
        { text: this.$lang.labels.searchType, value: 'searchType', sortable: false },
        { text: this.$lang.labels.required, value: 'required', sortable: false },
        { text: this.$lang.labels.relation, value: 'relation', sortable: false }
      ]
    }
  },
  created() {

    if (this.errorOrSuccess === 'SUCCESS') {
      this.addSnackbar({
        message: this.data.statusText,
        timeout: 5000,
        color: 'success'
      })
    } else {
      this.addSnackbar({
        message: this.data.statusText,
        timeout: 5000,
        color: 'error'
      })
    }
  },
  methods: {
    ...mapActions('app', ['addSnackbar'])

  }
}
</script>
<style lang="scss">
.inner-div {
  max-height: calc(90vh - 150px) !important;
}
</style>
